import { Injectable } from '@angular/core';
import { makeObservable, observable, runInAction } from 'mobx';
import { BreadcrumbItem, DetailsTab } from '../modals/details.modal';

@Injectable({ providedIn: 'root' })
export abstract class DetailsBaseStore<T> {
  isIntialLoading: boolean = true;
  isLoading: boolean = true;
  details: T = {} as T;
  protected abstract tabs: DetailsTab[];
  protected abstract breadcrumbs: BreadcrumbItem[];

  initialize(): void {} //@todo will make abstract later
  abstract get service(): any;
  abstract convertResponseToDTO(response: any): T;
  resetEntireChildState() {}

  constructor() {
    makeObservable(this, {
      isIntialLoading: observable,
      isLoading: observable,
      details: observable,
    });
  }

  fetchDetails(id: number): void {
    this.isLoading = true;
    this.service.get(id).subscribe({
      next: (response: T) => {
        runInAction(() => {
          this.details = this.convertResponseToDTO(response);
          this.isLoading = false;
          this.isIntialLoading = false;
        });
      },
      error: (error: any) => {
        console.error('Failed to fetch details:', error);
        runInAction(() => {
          this.isLoading = false;
          this.isIntialLoading = false;
        });
      },
    });
  }

  resetEntireState(): void {
    runInAction(() => {
      this.isIntialLoading = true;
      this.isLoading = true;
      this.details = {} as T;
      this.tabs = [];
      this.breadcrumbs = [];
      this.resetEntireChildState();
    });
  }
}
